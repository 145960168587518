<template>
  <h3>Product Category</h3>
</template>

<script>
export default {
  name: 'CategoryHome',
}
</script>

<style scoped>

</style>
